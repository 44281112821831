.footerBar{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #CFCECD;
}
.footer{
  width: 1200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footerLink{
  display: flex;
  gap: 2rem;
}
.footerLink span:hover{
  color: #B9BE10;
  cursor: pointer;
}
.footerNavLink{
  display: flex;
  gap: 1rem;

}
.packsizeCopyright{
  font-weight: 200;
  cursor: default;
}
.footerNavLink a{
  color: black;
  text-decoration: none;
}
.socialMediaIcons{
  display: flex;
  gap: 1rem;
  justify-content: center;
  text-decoration: none;
  color: #333;
}
.socialMediaLink{
  text-decoration: none;
// font-size: 30px;
color: #000;
width: 30px;
}
.socialMediaLink:hover {
  color: #B9BE10;
}

@media screen and (max-width: 1000px) {
  .footer,
  .footerLink
  {
    flex-direction: column;
    align-items: center;
  }
  .footer{
    gap: 2rem;
  }
}

@media screen and (max-width: 407px) {
  .packsizeCopyright{
    font-size: 14px;
  }
  .footerNavLink{
    flex-direction: column;
    text-align: center;
  }
}