/* Default styles */
.landingPage {
  position: relative;
}

.introImage {
  background-image: linear-gradient(to top left, #0068ab, #b6cee3);
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  // width: 100%;
  gap: 2rem;}

.introTitle {
  text-align: center;
}

.introNote {
  font-size: 36px;
  text-align: center;
  max-width: 1000px; /* Limit the max-width for larger screens */
  padding: 0 20px; /* Add padding to center content */
}

// .search input {
//   width: 100%;
//   max-width: 700px;
//   height: 40px;
//   border: none;
//   outline: none;
//   border-radius: 5px;
//   font-size: 20px;
//   padding: 10px;
// }

.introImageChild {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.introTitle {
  font-size: 70px;
}



/* Media query for screens below 1035px */
@media screen and (max-width: 1035px) {
  .introNote {
    max-width: none; /* Remove max-width for smaller screens */
    text-align: center; /* Center text on smaller screens */
  }
  .introImage{
    width: 100%;
  }

}

.mainBody {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
}
.mainBodyChild{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  // width: 1200px;
  // padding: 20px;
}
.CADFiles,
.overview{
  padding: 10px 0;
  border-bottom: 1px solid #e1e1e1;
}
.CADFilesChild,
.overviewChild {
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
@media screen and (max-width: 1035px) {
  .CADFilesChild,
  .overviewChild {
    grid-template-columns: repeat(2, 1fr); /* Display two cards per row below 1035px */
  }
  .CADFiles,
  .overview{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

}

@media screen and (max-width: 672px) {
  .CADFilesChild,
  .overviewChild {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 462px) {
  .introTitle{
    font-size: 50px;
  }
  .introNote{
    font-size: 26px;
  }
}

.overview h3,
.CADFiles h3{
  font-size: 24px;
}
.CADFilesCard,
.overviewCard {
  padding: 10px 0;
  width: 300px;
  box-shadow: 1px 5px 10px #d2d893;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.CADFilesCard:hover,
.overviewCard:hover {
  background-color: #d2d893;
  box-shadow: 1px 10px 10px #d2d893;
}
.CADFilesCard img,
.overviewCard img {
  margin: 10px 0;
  width: 50px;
}
.overviewTitle {
  font-size: 18px;
  text-align: center;
  /* padding: 20px 0; */
}
.CADFiles {
  margin: 30px 0;
}
.CADFiles h3 {
  font-size: 24px;
  margin-bottom: 10px;
}