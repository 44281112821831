.p_image{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  margin-left: .5rem;
  margin-bottom: -3rem;
}

.btn-container{
  margin-top: 14rem;
}

.logout_btn{
  width: 100%;
}

.user_img{
  height: 45px;
  margin-top: 8px;
  margin-right: 3rem;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /* display: block; */
  /* position: absolute; */
  background-color: grey;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style-type: none;
  margin-left: -3rem;
}

.dropdown-content ul {
  list-style-type: none;
  padding: 0;
}

.dropdown-content li {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
  color: black;
}


.pat_img{
  width: 50px;
}

.box {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.exit_img {
  width:20px;
  height:20px;
   cursor: pointer;
   /* padding-right: 20px; */
}
.logout_label{
  margin-left: 25px;
}




